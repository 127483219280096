





































import "@fontsource/roboto/300.css"; // Weight 500.
import "@fontsource/roboto/400.css"; // Weight 500.
import "@fontsource/roboto/500.css";
import { mdiChevronLeft } from "@mdi/js";
import Vue from "vue";
import router from "@/router";
import { useClient } from "@/composables/client";
import { toRefs, onMounted, ref } from "@vue/composition-api";

export default Vue.extend({
  name: "App",
  setup() {
    const goBack = () => {
      router.go(-1);
    };

    const clientRef = router.currentRoute.params.clientRef;
    const { currentClient, clientData, getClient } = useClient();

    let errorMsg = ref("Loading...");
    let errorStatus = ref(0);
    let errorDetail = ref("");
    let showError = ref(false);

    onMounted(async () => {
      try {
        await getClient(clientRef);
        localStorage.setItem("clientRef", clientRef);

        if (currentClient.value.passmanId === 0) {
          showError.value = true;
          errorMsg.value = "[FrontEnd] Client Inconnu";
        }
      } catch (error) {
        //Sentry.captureException(error);

        showError.value = true;
        errorMsg.value = "[FrontEnd] Erreur lors du chargement du client :";
        console.log(error.response);
        if (error.response) {
          errorStatus.value = error.response.status;
          errorDetail.value = error.response.statusText;
        } else {
          errorDetail.value = error;
        }
      }
    });

    return {
      ...toRefs(clientData),
      currentClient,
      mdiChevronLeft,
      goBack,
      showError,
      errorMsg,
      errorStatus,
      errorDetail,
    };
  },
});
