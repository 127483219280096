


















import { usePayment } from "@/composables/payment";
import { defineComponent, onMounted } from "@vue/composition-api";
import "@/assets/css/payzen-material-reset.css";
import BaseCard from "@/components/BaseCard.vue";
import { mdiLockOutline } from "@mdi/js";

export default defineComponent({
  props: {
    formToken: String,
  },
  components: { BaseCard },
  setup(props, context: any) {
    const { handlePayment, promiseError } = usePayment();

    onMounted(async () => {
      context.root
        .$loadScript(
          "https://static.payzen.eu/static/js/krypton-client/V4.0/ext/material.js"
        )
        .then(() => {
          handlePayment(props.formToken);
        })
        .catch(() => {});
    });
    return {
      promiseError,
      mdiLockOutline,
    };
  },
});
