// Full config:  https://github.com/axios/axios#request-config
// axios.defaults.baseURL = config.baseURL || config.apiUrl || '';
// axios.defaults.headers.common['Authorization'] = AUTH_TOKEN;
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

import axios from "axios";

const API_URL = config.VUE_APP_API_URL || "http://localhost:8080";

const configAxios = {
  baseURL: API_URL,
};

const _axios = axios.create(configAxios);

_axios.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(undefined, function (err) {
  if (
    !Object.prototype.hasOwnProperty.call(err.config, "errorHandle") ||
    err.config.errorHandle !== false
  ) {
    return new Promise(function () {
      if (err.response) {
        alert(
          "Une erreur est survenue au chargement du passboutik, si cela se reproduit contactez le support"
        );
      }
    });
  }
  throw err;
});

export default _axios;
