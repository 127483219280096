<template>
  <v-dialog id="cgu-content" v-model="cguDialogParent">
    <v-card>
      <v-card-title>
        <span class="headline">
          {{ $t("cgu.content.title") }}
        </span>
      </v-card-title>
      <v-card-text>
        <h2 class="mb-3 mt-3">{{ $t("cgu.content.definition_title") }}</h2>
        <div class="mb-3 mt-3" v-html="$t('cgu.content.definition_1')"></div>
        <div v-html="$t('cgu.content.definition_2')"></div>
        <div v-html="$t('cgu.content.definition_3')"></div>
        <h2 class="mb-3 mt-3">{{ $t("cgu.content.article_1_title") }}</h2>
        <div v-html="$t('cgu.content.article_1_content')"></div>
        <h2 class="mb-3 mt-3">{{ $t("cgu.content.article_2_title") }}</h2>
        <div v-html="$t('cgu.content.article_2_content')"></div>
        <h2 class="mb-3 mt-3">{{ $t("cgu.content.article_3_title") }}</h2>
        <div v-html="$t('cgu.content.article_3_content')"></div>
        <h2 class="mb-3 mt-3">{{ $t("cgu.content.article_4_title") }}</h2>
        <div v-html="$t('cgu.content.article_4_content')"></div>
        <h2 class="mb-3 mt-3">{{ $t("cgu.content.article_5_title") }}</h2>
        <h3>5.1.{{ $t("cgu.content.article_5_1_title") }}</h3>
        <div v-html="$t('cgu.content.article_5_1_content')"></div>
        <h3>5.1.{{ $t("cgu.content.article_5_2_title") }}</h3>
        <div v-html="$t('cgu.content.article_5_2_content')"></div>
        <h2 class="mb-3 mt-3">{{ $t("cgu.content.article_6_title") }}</h2>
        <div v-html="$t('cgu.content.article_6_content')"></div>
        <h2 class="mb-3 mt-3">{{ $t("cgu.content.article_7_title") }}</h2>
        <div v-html="$t('cgu.content.article_7_content')"></div>
        <h2 class="mb-3 mt-3">{{ $t("cgu.content.article_8_title") }}</h2>
        <div v-html="$t('cgu.content.article_8_content')"></div>
        <h2 class="mb-3 mt-3">{{ $t("cgu.content.article_9_title") }}</h2>
        <div v-html="$t('cgu.content.article_9_content')"></div>
        <h2 class="mb-3 mt-3">{{ $t("cgu.content.article_10_title") }}</h2>
        <div v-html="$t('cgu.content.article_10_content')"></div>
        <h2 class="mb-3 mt-3">{{ $t("cgu.content.article_11_title") }}</h2>
        <div v-html="$t('cgu.content.article_11_content')"></div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="[(cguDialogParent = false)]"
          >Fermer</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { computed } from "@vue/composition-api";

export default {
  props: {
    cguDialog: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const cguDialogParent = computed({
      get: () => props.cguDialog,
      set: (value) => {
        emit("update:cguDialog", value);
      },
    });
    return {
      cguDialogParent,
    };
  },
};
</script>

<style scoped>
div {
  margin-bottom: 12px;
  margin-top: 12px;
  line-height: 2rem;
}
</style>
