import Vue from "vue";
import VueRouter from "vue-router";

import Home from "../views/Home.vue";
import Payment from "../views/Payment.vue";
import PaymentDone from "../views/PaymentDone.vue";

import Error from "@/views/Error.vue";
import NotFound from "@/views/NotFound.vue";
import Package from "@/views/Package.vue";
import Form from "@/views/Form.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/:clientRef",
    name: "home",
    component: Home,
  },
  {
    path: "/:clientRef/package/:packageId",
    name: "package",
    component: Package,
  },
  {
    path: "/:clientRef/package/:packageId/form/:productId",
    name: "form",
    component: Form,
  },
  {
    path: "/:clientRef/payment/:formToken",
    name: "payment",
    component: Payment,
    props: true,
  },
  {
    path: "/:clientRef/payment-done",
    name: "payment-done",
    component: PaymentDone,
  },
  {
    path: "/error",
    name: "error",
    component: Error,
  },

  {
    path: "*",
    component: NotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.params.clientRef != null) {
    localStorage.setItem("clientRef", to.params.clientRef);
  }

  if (
    localStorage.getItem("clientRef") != null &&
    to.params.clientRef == null
  ) {
    to.params.clientRef = localStorage.getItem("clientRef") as string;
    next({ name: to.name as string, query: to.query, params: to.params });
  } else {
    next();
  }
});

export default router;
