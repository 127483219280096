import KRGlue from "@lyracom/embedded-form-glue";
import router from "@/router";
import _axios from "@/axios";
import { ref } from "@vue/composition-api";

export function usePayment() {
  const promiseError = ref("");

  function handlePayment(formToken: any) {
    {
      const endpoint = "https://static.payzen.eu";
      const publicKey = config.VUE_APP_PAYZEN_PUBLIC_KEY;

      KRGlue.loadLibrary(endpoint, publicKey) /* Load the remote library */
        .then(({ KR }: any) =>
          KR.setFormConfig({
            /* set the minimal configuration */
            formToken: formToken,
            "kr-language": "fr-FR" /* to update initialization parameter */,
          })
        )
        .then(({ KR }: any) =>
          KR.addForm("#myPaymentForm")
        ) /* create a payment form */
        .then(({ KR, result }: any) => {
          KR.showForm(result.formId);
          KR.onSubmit((event: any) => {
            if (event.clientAnswer.orderStatus === "PAID") {
              // Remove the payment form
              KR.removeForms();
              const metadata = event.clientAnswer.transactions[0].metadata;
              const orderId = event.clientAnswer.orderDetails.orderId;

              if (metadata != undefined && orderId != undefined) {
                _axios
                  .post("/control-user-payment", {
                    orderId: orderId,
                  })
                  .then(() => {});

                router
                  .push({
                    name: "payment-done",

                    query: {
                      codeTV: metadata.codeTV,
                    },
                  })
                  .catch(() => {});
              } else {
                router
                  .push({
                    name: "error",
                    query: {
                      errorMsg: "Payment failed, retry or contact the support.",
                    },
                  })
                  .catch(() => {});
              }
            } else {
              router
                .push({
                  name: "error",
                  query: {
                    errorMsg: "Payment failed, retry or contact the support.",
                  },
                })
                .catch(() => {});
            }
          });
        }) /* show the payment form */
        .catch(
          (error: any) =>
            (promiseError.value = error + " (see console for more details)")
        );
    }
  }

  return {
    handlePayment,
    promiseError,
  };
}
