<template>
  <v-btn :outlined="isDark" x-large rounded v-bind="$attrs" v-on="$listeners"
    ><slot>Empty</slot>
  </v-btn>
</template>

<script>
import { computed } from "@vue/composition-api";

export default {
  inheritAttrs: false,
  setup(props, context) {
    const isDark = computed(() => context.root.$vuetify.theme.dark);

    return {
      isDark,
    };
  },
};
</script>

<style scoped>
.v-btn.v-size--default {
  font-size: 21px;
}
</style>
