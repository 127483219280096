import Package from "@/models/Package.model";

export default class Client {
  public id: number;
  public name: string;
  public passmanId: number;
  public tvPackages: Array<Package>;

  constructor() {
    this.id = 0;
    this.name = "";
    this.passmanId = 0;
    this.tvPackages = [];
  }
}
