<template>
  <v-card
    height="100%"
    class="mx-auto"
    max-width="800"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-card-title
      :class="[
        'primary--text',
        'text-center',
        'justify-center',
        'mx-10',
        'pb-2',
      ]"
    >
      <v-icon color="primary" class="mr-1">{{ leftTitleIcon }}</v-icon>
      <slot name="title"></slot>
    </v-card-title>
    <v-card-text height="100%" class="mt-5">
      <slot>Description</slot>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    basic: {
      type: Boolean,
      default: false,
    },
    title: {
      type: Boolean,
      default: false,
    },
    leftTitleIcon: {
      type: String,
      default: "",
    },
  },
};
</script>
<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
.theme--dark.v-card {
  background-color: rgba(0, 0, 0, 0.8);
}
.theme--light.v-card {
  background-color: rgba(255, 255, 255, 0.9);
}
</style>
