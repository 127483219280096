import "./composition-api.ts";
import "babel-polyfill";
import Vue from "vue";
import "axios";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "@/router";
import * as Sentry from "@sentry/browser";
import { Vue as VueIntegration } from "@sentry/integrations";
import "material-design-icons-iconfont/dist/material-design-icons.css";
import LoadScript from "vue-plugin-load-script";
import i18n from "./i18n";

if (config.VUE_APP_SENTRY_DSN != undefined && config.VUE_APP_SENTRY_DSN != "") {
  Sentry.init({
    dsn: config.VUE_APP_SENTRY_DSN,
    integrations: [new VueIntegration({ Vue, attachProps: true })],
  });
}
Vue.use(LoadScript);

Vue.config.productionTip = false;

new Vue({
  vuetify,
  router,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
