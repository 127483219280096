<template>
  <div class="cgu-checkbox">
    <v-checkbox
      required
      :rules="cguRules"
      :input-value="value"
      :disabled="cguDialog"
      @change="updateCgu"
    >
      <span slot="label" class="text-h3 secondary--text">
        {{ $t("cgu.text_1") }}
        <b
          @click.stop="[(cguDialog = true)]"
          style="text-decoration: underline"
          class="text-h3 secondary--text font-weight-medium"
        >
          {{ $t("cgu.link") }}
        </b>
      </span>
    </v-checkbox>
    <cgu-content-dialog
      @update:cguDialog="cguDialog = $event"
      :cgu-dialog="cguDialog"
    ></cgu-content-dialog>
  </div>
</template>

<script>
import CguContentDialog from "@/components/CguContentDialog";
import { ref } from "@vue/composition-api";

export default {
  name: "BaseCgu",
  props: {
    value: {
      type: [Boolean, String],
      default: false,
    },
  },
  components: {
    CguContentDialog,
  },
  setup(props, context) {
    const cguRules = [
      (value) => !!value || context.root._i18n.t("cgu.validation.required"),
    ];

    function updateCgu(value) {
      context.emit("input", value);
    }

    return {
      cguRules,
      updateCgu,
      cguDialog: ref(false),
    };
  },
};
</script>

<style scoped></style>
