


































































import BaseTextField from "@/components/BaseTextField.vue";
import BaseButton from "@/components/BaseButton.vue";
import { useUserForm } from "@/composables/form";
import { useClient } from "@/composables/client";
import { onMounted, ref, toRefs } from "@vue/composition-api";
import { mdiAccountOutline, mdiEmailOutline, mdiEarth } from "@mdi/js";
import router from "@/router";
import BaseCgu from "@/components/BaseCgu.vue";
import Package from "@/models/Package.model";
import Product from "@/models/Product.model";
import BaseCard from "@/components/BaseCard.vue";

export default {
  components: {
    BaseCard,
    BaseCgu,
    BaseButton,
    BaseTextField,
  },
  setup() {
    const { userFormData, sendForm, userFormRules } = useUserForm();
    const {
      getProduct,
      getPackage,
      formatPrice,
      libelleDuration,
      currentClient,
    } = useClient();

    let packageId = parseInt(router.currentRoute.params.packageId);
    packageId = isNaN(packageId) ? 0 : packageId;

    let productId = parseInt(router.currentRoute.params.productId);
    productId = isNaN(productId) ? 0 : productId;

    const appPackage = ref<Package | null>(null);
    const product = ref<Product | null>(null);

    onMounted(() => {
      let localPackage = getPackage(packageId);
      if (localPackage != undefined) {
        appPackage.value = localPackage;
      }
      let localProduct = getProduct(packageId, productId);
      if (localProduct != undefined) {
        product.value = localProduct;
      }
    });

    return {
      ...toRefs(userFormData),
      userFormRules,
      currentClient,
      sendForm,
      product,
      appPackage,
      formatPrice,
      libelleDuration,
      mdiAccountOutline,
      mdiEmailOutline,
      mdiEarth,
    };
  },
};
