<template>
  <v-text-field
    outlined
    dense
    persistent-hint
    :value="value"
    @input="updateValue"
    v-bind="$attrs"
    class="font-weight-medium"
  ></v-text-field>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number],
      default: "",
    },
  },
  setup(props, context) {
    function updateValue(value) {
      context.emit("input", value);
    }

    return { updateValue };
  },
};
</script>

<style scoped></style>
