


























import router from "@/router";
import BaseCard from "@/components/BaseCard.vue";
import BaseButton from "@/components/BaseButton.vue";

export default {
  components: { BaseCard, BaseButton },
  setup() {
    const codeTV = router.currentRoute.query.codeTV as string;

    return { codeTV };
  },
};
