



































import { useClient } from "@/composables/client";
import { onMounted, ref } from "@vue/composition-api";
import { mdiAccountOutline, mdiEmailOutline, mdiEarth } from "@mdi/js";
import router from "@/router";
import Package from "@/models/Package.model";
import BaseCard from "@/components/BaseCard.vue";

export default {
  components: { BaseCard },
  setup() {
    const { getPackage, formatPrice, libelleDuration } = useClient();

    let packageId = parseInt(router.currentRoute.params.packageId);
    packageId = isNaN(packageId) ? 0 : packageId;
    const appPackage = ref<Package | null>(null);

    onMounted(() => {
      let localPackage = getPackage(packageId);
      if (localPackage != undefined) {
        appPackage.value = localPackage;
      }
    });

    return {
      appPackage,
      formatPrice,
      libelleDuration,
      mdiAccountOutline,
      mdiEmailOutline,
      mdiEarth,
    };
  },
};
