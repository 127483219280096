import { reactive } from "@vue/composition-api";
import router from "@/router";
import _axios from "@/axios";
import Vue from "vue";
import { AxiosRequestConfig } from "axios";

export function useUserForm() {
  const userFormData = reactive({
    userFormFields: [],
    email: "",
    confirmation: "",
    firstname: "",
    lastname: "",
    cgu: false,
    userFormValid: false,
    userFormRef: {},
    isLoading: false,
  });

  const userFormRules = {
    firstname: [(v: any) => !!v || "Le prénom est obligatoire"],
    lastname: [(v: any) => !!v || "Le nom est obligatoire"],
    email: [
      (value: any) => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || "L'email est invalide";
      },
    ],
    confirmation: [
      (v: any) => !!v || "L'email de confirmation est obligatoire",
      (v: any) =>
        userFormData.email.length === 0 ||
        v === userFormData.email ||
        "L'email de confirmation est invalide",
    ],
  };

  function sendForm(clientId: number, productId: number) {
    if (userFormData.userFormRef) {
      const isFormValid = (
        userFormData.userFormRef as Vue & {
          validate: () => boolean;
        }
      ).validate();
      if (!isFormValid) {
        return;
      }
    }
    userFormData.isLoading = true;
    _axios
      .post(
        "/user-form-paid/" + clientId,
        {
          email: userFormData.email,
          firstname: userFormData.firstname,
          lastname: userFormData.lastname,
          productId: productId,
        },
        {
          errorHandle: false,
        } as AxiosRequestConfig
      )
      .then((response) => {
        userFormData.isLoading = false;

        router
          .push({
            name: "payment",
            params: {
              formToken: response.data.formToken,
            },
          })
          .catch(() => {});
      })
      .catch((error) => {
        userFormData.isLoading = false;
        alert("une erreur est survenue");
      })
      .finally(() => {
        userFormData.isLoading = false;
      });
  }

  return {
    userFormData,
    userFormRules,
    sendForm,
  };
}
