<template>
  <div>
    <base-card>
      <template #title>Boutique en Ligne</template>
      <template>
        <v-row
          ><v-col cols="12" sm="6" offset-sm="3"
            >Choisissez votre forfait TV :</v-col
          ></v-row
        >
        <v-row>
          <v-col
            cols="12"
            sm="6"
            offset-sm="3"
            v-for="tvPackage in currentClient.tvPackages"
            :key="tvPackage.id"
          >
            <v-btn
              width="100%"
              :to="{
                name: 'package',
                params: {
                  packageId: tvPackage.id,
                },
              }"
              color="secondary"
              >{{ tvPackage.name }}
            </v-btn>
            <span class="text-body-1 primary--text">{{
              tvPackage.description
            }}</span>
          </v-col>
        </v-row>
      </template>
    </base-card>
  </div>
</template>

<script>
import { useClient } from "@/composables/client";
import BaseCard from "@/components/BaseCard";

export default {
  components: { BaseCard },
  setup() {
    const { currentClient } = useClient();

    return {
      currentClient,
    };
  },
};
</script>
