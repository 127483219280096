import { reactive, ref } from "@vue/composition-api";
import Product from "@/models/Product.model";
import Package from "@/models/Package.model";
import Client from "@/models/Client.model";
import _axios from "@/axios";
import { AxiosRequestConfig } from "axios";
import { Duration } from "luxon";
import i18n from "@/i18n";

const currentClient = ref(new Client());

export function useClient() {
  const clientData = reactive({
    isLoading: false,
  });

  function getClient(clientRef: string) {
    clientData.isLoading = true;
    return _axios
      .get("/clients/" + clientRef, {
        errorHandle: false,
      } as AxiosRequestConfig)
      .then((response) => {
        currentClient.value = response.data;
        clientData.isLoading = false;
      });
  }

  function formatPrice(price: number, currency: string) {
    // degraded mode, aka for Nintendo Switch whithout Intl
    if (!window.Intl) {
      return price + " " + currency;
    } else {
      return new Intl.NumberFormat("fr-FR", {
        style: "currency",
        currency: currency,
      }).format(price);
    }
  }
  function getPackage(packageId: number): Package | undefined {
    return currentClient.value.tvPackages.find(
      (x: Package) => x.id === packageId
    );
  }
  function getProduct(
    packageId: number,
    productId: number
  ): Product | undefined {
    const currentPackage = getPackage(packageId);

    if (currentPackage != undefined) {
      return currentPackage.products.find((x: Product) => x.id === productId);
    }

    return undefined;
  }

  function libelleDuration(duration: number): string {
    const durationLuxon = Duration.fromObject({
      seconds: duration,
      days: 0,
      hours: 0,
      minutes: 0,
      weeks: 0,
      months: 0,
      years: 0,
    })
      .normalize()
      .toObject();
    let durationString = "";

    if (
      durationLuxon.years != undefined &&
      durationLuxon.months != undefined &&
      durationLuxon.weeks != undefined &&
      durationLuxon.days != undefined &&
      durationLuxon.hours != undefined &&
      durationLuxon.minutes != undefined &&
      durationLuxon.seconds != undefined
    ) {
      if (durationLuxon.years >= 1) {
        durationString =
          durationLuxon.years +
          " " +
          i18n.tc("date.years", durationLuxon.years);
      } else if (durationLuxon.months >= 1) {
        durationString =
          durationLuxon.months +
          " " +
          i18n.tc("date.months", durationLuxon.months);
      } else if (durationLuxon.weeks >= 1) {
        durationString =
          durationLuxon.weeks +
          " " +
          i18n.tc("date.weeks", durationLuxon.weeks);
      } else if (durationLuxon.days >= 1) {
        durationString =
          durationLuxon.days + " " + i18n.tc("date.days", durationLuxon.days);
      } else if (durationLuxon.hours >= 1) {
        durationString =
          durationLuxon.hours +
          " " +
          i18n.tc("date.hours", durationLuxon.hours);
      } else if (durationLuxon.minutes >= 1) {
        durationString =
          durationLuxon.minutes +
          " " +
          i18n.tc("date.minutes", durationLuxon.minutes);
      }
    }

    return durationString;
  }

  return {
    clientData,
    getClient,
    getPackage,
    currentClient,
    getProduct,
    formatPrice,
    libelleDuration,
  };
}
